<template>
  <v-container>
    <!-- BOC:[breadcrumbs] -->
    <ABreadcrumbV1 :items="breadcrumbs"></ABreadcrumbV1>
    <!-- EOC -->
    <!-- BOC:[grid] -->
    <v-row>
      <template v-for="item in menu">
        <v-col :key="item.name" class="col-12 col-sm-6 col-lg-3 text-center">
          <v-card
            elevation="2"
            outlined
            width="344"
            :to="item.route"
            :loading="loading"
          >
            <v-card-text>
              <v-icon class="mr-2">{{ item.icon }}</v-icon>
              <span>{{ $t(item.translatableName) }}</span>
              <span><Count @loadingCard = 'loadingCard' :path="item.path" :server="item.server" /></span>
            </v-card-text>
          </v-card>
        </v-col>
      </template>
    </v-row>
    <v-row> </v-row>

    <!-- EOC -->
  </v-container>
</template>

<script>
import menu from "@/datasets/menu";
import { mapState } from "vuex";
import Count from "@/components/Moderator/Dashboard/Count";
export default {
  components: {
    //
    Count,
  },
  computed: mapState({
    //
  }),
  props: [
    //
  ],
  data: () => ({
    loading: true,
    //BOC:[breadcrumbs]
    breadcrumbs: [],
    //EOC
    //BOC:[grid]
    menu: menu.Moderator,
    //EOC
  }),
  created() {
    //BOC:[breadcrumbs]
    this.breadcrumbs.push({
      text:this.$t("model.name.dashboard"),
      to: { name: "PageModeratorDashboard" },
      exact: true,
    });
    //EOC
  },
  mounted() {
  

  },
  methods: {
    //
    loadingCard(){
  this.loading =false
}
  },
};
</script>